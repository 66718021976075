@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    scroll-behavior: smooth;
}

body {
    padding: 0px !important;
    margin: 0px !important;
}

/* Zeeshan */
.reviews {
    margin: 0px !important;
}

.heading-img-width {
    width: 70px;
    height: auto;
    /* margin-left: 5px; */
    margin-bottom: 15px;
    background: transparent;
}

/* Image styling of reviews section */
.image-styling {
    width: 100%;
    height: auto;
    margin: auto;
}

.image-border-style {
    margin: auto !important;
}

@media (min-width: 992px) {
    .image-border-style {
        margin: 0 10px !important;
        padding: 0 !important;
    }
}

.review-sec-border {
    border: 2px solid #636ce4 !important;
    border-radius: 20px;
}

/* Get started container styling */

.get-started-container {
    border: 6px solid #636ce4;
    border-radius: 40px;
    max-width: 100%;
    height: auto;
}

.blue-background {
    background-color: rgba(203, 206, 249, 0.5);
    /* Adjust the last value (alpha) to set the transparency */
}

/* Custom styles for LG screens and above */
@media (min-width: 992px) {
    .get-started-container {
        width: 900px;
    }
}

.getstarted-img-width1 {
    width: 150px;
}

.getstarted-img-width2 {
    width: 50px;
}

/* Try yourself section styling */
.tryyourself-heading {
    font-size: 42px;
    font-weight: bold;
}

.bot-img-width {
    width: 70px;
    margin-left: 5px;
    margin-bottom: 15px;
    background: transparent;
    transition: transform 0.3s ease;
}

.bot-img-width:hover {
    cursor: pointer !important;
    transform: scale(1.2);
    /* Adjust the scale factor as needed */
}

@media (min-width: 992px) {
    .tryyourself-heading {
        font-size: 70px;
    }
}

.tryyourself-border {
    border: 6px solid #636ce4;
    border-radius: 40px;
}

.quiz-img-width {
    width: 140px;
    height: 100px;
}

input[type="radio"]:checked {
    background-color: #13d155;
    border: 0px;
}

/* Login page styling start from here */
.form-width {
    max-width: 500px;
}

input::placeholder {
    color: rgb(224, 209, 209) !important;
    cursor: pointer;
}

@media (min-width: 992px) {
    .col-lg-4 {
        max-width: 30.333333% !important;
    }
}

.form-check-input {
    width: 20px;
    height: 20px;
}

/* RAFEH.. */

.main_quiz_div {
    border: 1px solid black;
}

.ask_btn {
    background-color: #000000;
    color: white;
}

.btn-ask {
    padding: 10px;
    color: white;
    background-color: #000000;
}

.ask-container {
    width: 150px;
    height: 200px;
    background-color: white;
    color: black;
    z-index: 999;
    border-radius: 5px;
    font-size: 10px;
    overflow: auto;
}

.ask-container h3 {
    padding: 5px;
}

.second-ask-container {
    width: 150px;
    height: 200px;
    background-color: white;
    color: black;
    z-index: 999;
    border-radius: 5px;
    font-size: 10px;
    overflow: auto;
}

.second-ask-container h3 {
    padding: 5px;
}

.ask-div .icon-minus {
    display: flex;
    justify-content: end;
    padding: 10px;
    font-size: 18px;
    position: relative;
}

.icon-minus i {
    font-size: 15px;
    float: right;
    margin: 3px;
    cursor: pointer;
}

.main-demo-container {
    position: relative;
}

.ask-button-setting {
    position: absolute;
    right: -50;
}

.modal_subscription {
    font-size: 20px;
    font-weight: 700;
}

.overlay-border {
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.border-overlay {
    position: absolute;
    border: 2px solid #ffffff;
    /* Adjust border color as needed */
    background-color: rgba(255, 255, 255, 0.2);
    /* Adjust background opacity as needed */
}

.footer_contact_us {
    text-decoration: none;
}

.no-scroll {
    overflow: hidden;
}

.input-group-text {
    border-radius: 0px !important;
}

.modal_subs {
    background: #4e5992;
    margin-bottom: 20px;
}
.main_modal_subs {
    display: flex !important;
    /* justify-content: center !important;
    align-items: center !important; */
}

@media (max-width: 550px) {
    .main_modal_subs {
        display: block !important;
    }
}
/* .color_icon_summarize{
    display: block;
} */
@media (max-width: 600px) {
    /* .color_icon {
        display: none !important;
    } */
    .summarize_input {
        padding-left: 45px !important;
    }
}
.main_grid_section {
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 24px;
    row-gap: 24px;
    grid-column-gap: 16px;
    column-gap: 16px;
}
.summarize_input {
    display: flex;
    max-width: 658px;
    width: 100%;
    height: 56px;
    color: black;
    border-radius: 8px;
    padding-left: 50px;
}
.ytsummarize_input {
    height: 50px;
    border-radius: 8px;
    padding: 10px 85px 10px 45px;
    color: black;
    background-color: white;
    max-width: 658px;
    width: 100%;
}

.ytsummarize_input:focus-visible {
    outline: none;
}
.summarize_input:focus-visible {
    outline: none !important;
}
.color_icon {
    color: rgb(255, 3, 2);
}
.color_icon_summarize{
    color: rgb(255, 3, 2);
    
}
@keyframes blink {
    0%, 100% { opacity: 0; }
    50% { opacity: 1; }
  }
  
  .blinking-dots::after {
    content: '...';
    animation: blink 1s infinite;
  }
  
.summarize_btn {
    height: 100%;
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    text-align: center;
    color: white;
    border-radius: 0 8px 8px 0;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
    z-index: 99999999;
}

@media (min-width: 1148px) and (max-width: 3000px) {

    .last_coloum{
        display: block !important;
    }
    .coloum_middle{
        display: none !important;
    }
}
@media (min-width: 320px) and (max-width: 500px) {
    .last_icons {
        display: grid;
        grid-template-columns: repeat(3, 1fr); /* Creates three columns */
        gap: 15px; /* Keeps your gap as defined earlier */
    }
}

@media (min-width: 320px) and (max-width: 1157px) {
    .one_coloum {
        flex: 0 0 100%;
        max-width: 100%;

    }
    .coloum_middle {
        flex: 0 0 100%;
        max-width: 100%;
        display: block !important;
    }
    .last_coloum{
        display: none !important;
    }

}
/* SO HERE... IS THE DEMO */

.cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px;
    overflow-y: auto;
    max-height: 500px;
  }

  /* Custom scrollbar styles */
.cards-container::-webkit-scrollbar {
    width: 8px; /* Scrollbar width */
}

.cards-container::-webkit-scrollbar-track {
    background: #000000; /* Track color */
    border-radius: 10px;
}

.cards-container::-webkit-scrollbar-thumb {
    background: #888; /* Handle color */
    border-radius: 10px;
}

.cards-container::-webkit-scrollbar-thumb:hover {
    background: #555; /* Handle color on hover */
}
  
.cardsssss {
    /* box-shadow: -2px 1px 8px rgb(255 62 62 / 76%); */
    width: 300px;
    border-radius: 8px;
    background-color: black;
    overflow: hidden;
    transition: transform 0.2s;
    height: 160px !important;
}

  
  .cardssss-body {
    padding: 20px;
    font-size: 16px;
    line-height: 1.5;
    color: white;
    height: fit-content !important;
    max-height: 200px; /* Adjust this value based on your design needs */
  }
.truncate-text {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;    
}
.ql-container{
    height: 500px !important;
    overflow-y: auto;
}
/* #content-to-export {
    overflow: visible !important;
    height: auto !important;
} */

@media (min-width:320px) and (max-width:767px){
    .cards-container{
        height: 352px !important;
    }
    .final_class{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

 
}


@media (min-width:320px) and (max-width:767px){
    
       .main_export{    
           position: absolute;
           top: 21px !important;
       }
       .ql-container{
        height: 350px !important;
       }
       .ql_container{
        height: 420px !important;
       }
}
@media print {
    /* Specific styles for printing */
    .ql-editor {
        color: black; 
    }
}

/* CSS FOR LOADER */
/* Define color-loader as a variable */
 /* Replace with your color */

